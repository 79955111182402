<template>
  <section>
      <div class="content-header">
        <h2>Works cited</h2>
      </div>
      <div class="content-wrapper">
        <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
        <ul>
          <li class="pb-2">Brecher, D. (2021). Habits videos.</li>
          <li class="pb-2">Wood, W., & Runger, D. (2016). Psychology of Habit. Annual Review of Psychology. Volume 67. Issue 1. 1-28</li>
          <li class="pb-2">Weir, K (2012). What you need to know about willpower: The Psychological Science of Self-Control. APA website (2012). Willpower.</li>
          <li class="pb-2"><em>Personality Test, Personality Assessment: VIA Survey | VIA Institute</em>. (2021). VIA Institute on Character. <a href="https://www.viacharacter.org/survey/account/register" target="_blank">www.viacharacter.org/survey/account/register</a></li>
          <li class="pb-2">Brecher, D. (2021). Character Strengths videos.</li>
          <li class="pb-2">Brecher, D. (2021). Sleep video.</li>
          <li class="pb-2">Foster, R. (2013, August 14). <em><a href="https://www.ted.com/talks/russell_foster_why_do_we_sleep?language=en" target="_blank">Why do we sleep?</a></em>  [Video]. TED Talks. </li>
          <li class="pb-2"><em>The Okanagan Charter | wellbeing.ubc.ca.</em> (n.d.). <a href="https://wellbeing.ubc.ca/okanagan-charter" target="_blank">Okanagan Charter</a>.</li>
          <li>Walker, M. (2019, May 10). <em><a href="https://www.ted.com/talks/matt_walker_sleep_is_your_superpower?language=en" target="_blank">Sleep is your superpower</a></em> [Video]. TED Talks. </li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
